import ManagersView from "../ManagersView.vue";
import ManagersList from "../Pages/ManagersList.vue";
import ManagerEditor from "../Pages/ManagerEditor.vue";

const routes = [
  {
    path: 'jefes-de-ventas',
    component: ManagersView,
    name: 'managers',
    children: [
      {
        path: '',
        name: 'celulares-managers-list',
        component: ManagersList
      },
      {
        path: 'crear',
        name: 'celulares-managers-create',
        component: ManagerEditor
      }
    ]
  },
]

export default routes
