<template>
  <div class="page-container base-batch-list">
    <div class="page-title-container">
      <h1 class="page-title">Lista de cargas base</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por nombre del archivo, cargado por"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
              <div class="text-right mt-2 mt-md-0">
                <b-button
                  :to="{ name: 'celulares-base-batch-upload' }"
                  variant="none"
                  class="button button-primary ml-md-2"
                >Cargar</b-button
                >
              </div>
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Fecha de carga</span>
            </div>
            <div>
              <span>Nombre del archivo</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span v-local-date:hour="o.createdAt"></span>
                </div>

                <div>
                  <span>{{ o.file.name }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>

              <div
                class="custom-table-mobile-options mt-3"
              >
                <a
                  href=""
                  @click.prevent="download(o.file)"
                  class="mr-2"
                >Descargar</a
                >
              </div>

              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Cargado por:</span
                    >
                    <span>{{ o.createdBy }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
          <tr>
            <th>Fecha de carga</th>
            <th>Nombre del archivo</th>
            <th>Cargado por</th>
            <th class="pr-md-3">Opciones
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in list" :key="o.id">
            <td class="cell-center px-1" v-local-date:hour="o.createdAt"></td>
            <td>{{ o.file.name }}</td>
            <td class="cell-center">{{ o.createdBy }}</td>
            <td class="cell-center d_option pr-md-2 position-relative">
              <DropdownTable>
                <template v-slot:dropdown-content>
                  <DropdownItem
                    text="Descargar"
                    @click="download(o.file)"
                    v-if="o.file"
                  />
                </template>
              </DropdownTable>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BatchService } from "../../Core/Services";

import { Constants, DropdownItem, DropdownTable, FilterMenu, FilterMixin, PaginationMixin, SearchBar, Util,Alert } from "wize-admin";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      filterOptions: [
        {
          label: "Fecha de carga",
          key: "createdAt",
          type: Constants.TypeFilters.DATE,
          isRange: true
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "period,desc",
        };

        const data = { search: this.search, ...this.filters };
        const response = await BatchService.list(data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    download(asset) {
      if (asset) Util.downloadFile(asset.url, asset.name);
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
  },
  async created() {

  },
};
</script>

<style lang="stylus" scoped>
</style>
