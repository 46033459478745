import StatusView from "../StatusView.vue";
import StatusList from "../Pages/StatusList.vue";
import StatusDetailList from "../Pages/StatusDetailList.vue";
import PaymentCodeStatusDetailList from "../Pages/PaymentCodeStatusDetailList.vue";

const routes = [
  {
    path: 'status',
    component: StatusView,
    children: [
      {
        path: '',
        name: 'celulares-status',
        component: StatusList
      },
      {
        path: ':batchId/detalle',
        name: 'celulares-status-details',
        component: StatusDetailList,
      },
      {
        path: ':batchId/detalle/:paymentCode',
        name: 'celulares-status-details-payment-code',
        component: PaymentCodeStatusDetailList,
      }
    ]
  },
]

export default routes
