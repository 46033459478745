<template>
  <dropdown-basic
    class="businesses-dropdown"
    text="Celulares AD"
    :left="true"
  >
    <template #dropdown-content>
      <!-- Título -->
      <b-dropdown-item
        class="dropdown-title"
        @click="$router.push({ name: 'businesses' })"
        >Ver todos los proyectos</b-dropdown-item
      >
    </template>
  </dropdown-basic>
</template>

<script>
import { DropdownBasic } from "wize-admin";

export default {
  components: {
    DropdownBasic,
  },
  methods: {
  },
};
</script>

<style lang="stylus">
@import "../../Styles/components/layout/businesses-dropdown.styl"
</style>
