import SellersView from "../SellersView.vue";
import SellersList from "../Pages/SellersList.vue";
import SellersEditor from "../Pages/SellersEditor.vue";

const routes = [
  {
    path: 'vendedores',
    component: SellersView,
    children: [
      {
        path: '',
        name: 'celulares-sellers-list',
        component: SellersList,
      },
      {
        path: 'crear',
        name: 'celulares-sellers-create',
        component: SellersEditor
      }
    ]
  }
]

export default routes
