const Errors = {
  SERVER_ERROR: "Ocurrió un error en la petición, por favor intenta nuevamente.",
}

const ZonalStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

const IdentifierType = {
  DNI: {key: 1, label: 'DNI'},
  CE: {key: 4, label: 'CARNE DE EXTRANJERIA'},
  RUC: {key: 6, label: 'RUC'},
  PASAPORTE: {key: 7, label: 'PASAPORTE'},
  CARNE_REFUGIO: {key: 9, label: 'CARNE DE SOLICITUD DE REFUGIO'},
  CARNE_IDENTIDAD: {key: 22, label: 'CARNE DE IDENTIDAD – RR.EE.'},
  CARNE_PERMANENCIA: {key: 23, label: 'CARNE PERMISO TEMP. PERMANENCIA'},
  DNI_EXTRANJERO: {key: 24, label: 'DOC. DE IDENTIDAD EXTRANJERO'},
  LIBRETA_MILITAR: {key: 90, label: 'LIBRETA MILITAR'},
  CARNET_MILITAR: {key: 91, label: 'CARNET MILITAR'},
  OTROS: {key: 0, label: 'OTROS'},
}

const SellersStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-red'},
}

const SellersTypes = {
  SALES_EXECUTIVE: {key: 1, label: 'Ejecutivo de venta'},
  RELIEF_SALES_EXECUTIVE: {key: 2, label: 'Ejecutivo de venta volante'}
}

const SupervisorsStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'}

}

const SupervisorTypes = {
  AD: {key: 1, label: 'Supervisor AD', color: 'badge-green'},
  PUSHER: {key: 2, label: 'Supervisor Impulsador', color: 'badge-yellow'}
}

const ManagerStatus = {
  ACTIVE: {key: 1, label: 'ACTIVO', color: 'badge-green'},
  INACTIVE: {key: 2, label: 'SUSPENDIDO', color: 'badge-yellow'},
}

export default {
  Errors,
  ZonalStatus,
  IdentifierType,
  SellersStatus,
  SellersTypes,
  SupervisorsStatus,
  SupervisorTypes,
  ManagerStatus
}
