import SupervisorsView from "../SupervisorsView.vue";
import SupervisorsList from "../Pages/SupervisorsList.vue";
import SupervisorEditor from "../Pages/SupervisorEditor.vue";

const routes = [
  {
    path: 'supervisores',
    component: SupervisorsView,
    children: [
      {
        path: '',
        name: 'celulares-supervisors-list',
        component: SupervisorsList
      },
      {
        path: 'crear',
        name: 'celulares-supervisors-create',
        component: SupervisorEditor
      }
    ]
  },
]

export default routes
