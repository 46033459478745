import CelularesView from "../CelularesView.vue";
import DashboardRoutes from "../Dashboard/Routes";
import BaseBatchRoutes from "../BaseBatch/Routes";
import StatusRoutes from "../Status/Routes";
import SellersRoutes from "../Sellers/Routes";
import SupervisorsRoutes from "../Supervisors/Routes"
import ManagersRoutes from '../Managers/Routes'

let children = [];

children = children.concat(DashboardRoutes, BaseBatchRoutes, StatusRoutes, SellersRoutes, SupervisorsRoutes, ManagersRoutes);

const routes = [
  {
    path: '/celulares-ad',
    component: CelularesView,
    children: children
  }
]

export default routes
