<template>
  <div class="page-container product-editor">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar jefe de venta" : "Nuevo jefe de venta" }}
      </h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
            tag="article"
            no-body
            style="max-width: 760px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex align-items-center"
            >
              <h3>
                {{
                  editing
                    ? "Datos del jefe de venta"
                    : "Datos del nuevo jefe de venta"
                }}
              </h3>
            </b-card-header>
            <b-card-body class="custom-card-body px-0 pb-0">
              <form class="custom-form" @submit.prevent="save" novalidate>
                <div class="form-section pt-0">
                  <h2 class="custom-card-subtitle mb-4">Información general</h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="redirectTo">Tipo de documento *</label>

                        <FormSelect
                          id="redirectTo"
                          :show-error="
                            $v.form.identifierType.$error &&
                            !$v.form.identifierType.required
                          "
                          v-model="form.identifierType"
                          :items="identifierTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.identifierType.$error &&
                            !$v.form.identifierType.required
                          "
                        ></form-error>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="identifier">Número de documento *</label>

                        <FormInput
                          id="identifier"
                          type="number"
                          maxlength="20"
                          placeholder="Ingresar número de documento"
                          :show-error="
                            $v.form.identifier.$error &&
                            !$v.form.identifier.required
                          "
                          v-model="form.identifier"
                        />

                        <form-error
                          message="Número de documento es requerido"
                          v-if="
                            $v.form.identifier.$error &&
                            !$v.form.identifier.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="identifier">Nombres *</label>

                        <FormInput
                          id="name"
                          type="text"
                          placeholder="Ingresar nombres"
                          :show-error="
                            $v.form.name.$error && !$v.form.name.required
                          "
                          v-model="form.name"
                        />

                        <form-error
                          message="Nombres son requeridos"
                          v-if="$v.form.name.$error && !$v.form.name.required"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="lastname">Apellidos *</label>

                        <FormInput
                          id="lastname"
                          type="text"
                          placeholder="Ingresar apellidos"
                          :show-error="
                            $v.form.lastname.$error &&
                            !$v.form.lastname.required
                          "
                          v-model="form.lastname"
                        />

                        <form-error
                          message="Apellidos son requeridos"
                          v-if="
                            $v.form.lastname.$error &&
                            !$v.form.lastname.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section">
                  <h2 class="custom-card-subtitle mb-4">
                    Información de contacto
                  </h2>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="email"
                          >Correo Electrónico Corporativo *</label
                        >
                        <FormInput
                          id="email"
                          type="email"
                          :disabled="editing"
                          maxlength="100"
                          placeholder="Ingresar correo electrónico"
                          :show-error="
                            $v.form.email.$error && !$v.form.email.required
                          "
                          v-model="form.email"
                        />
                        <form-error
                          message="Correo electrónico es requerido"
                          v-if="$v.form.email.$error && !$v.form.email.required"
                        ></form-error>

                        <form-error
                          message="Ingrese un correo electrónico válido"
                          v-if="!$v.form.email.email && $v.form.email.$error"
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="phone">Número Celular Corporativo *</label>
                        <FormInput
                          id="phone"
                          type="text"
                          :disabled="editing"
                          maxlength="9"
                          placeholder="Ingresar número celular corporativo"
                          :show-error="
                            $v.form.phone.$error && !$v.form.phone.required
                          "
                          v-model="form.phone"
                        />
                        <form-error
                          message="Número celular corporativo es requerido"
                          v-if="$v.form.phone.$error && !$v.form.phone.required"
                        ></form-error>

                        <form-error
                          message="Ingrese un número válido"
                          v-if="!$v.form.phone.email && $v.form.phone.$error"
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section pb-0 border-0">
                  <h2 class="custom-card-subtitle mb-4">Información Laboral</h2>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email">Zonales</label>

                        <div class="d-flex">
                          <b-form-checkbox
                            :id="i"
                            name="check-button"
                            v-for="i in 5"
                            :key="i"
                            class="mr-3"
                          >
                          Zonal {{ i }}
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="redirectTo">Canal</label>

                        <FormSelect
                          id="redirectTo"
                          :show-error="
                            $v.form.channel.$error &&
                            !$v.form.channel.required
                          "
                          v-model="form.channel"
                          :items="channelTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.channel.$error &&
                            !$v.form.channel.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="redirectTo">Línea de Gasto</label>

                        <FormSelect
                          id="redirectTo"
                          :show-error="
                            $v.form.channel.$error &&
                            !$v.form.channel.required
                          "
                          v-model="form.channel"
                          :items="channelTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.channel.$error &&
                            !$v.form.channel.required
                          "
                        ></form-error>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="redirectTo">Área</label>

                        <FormSelect
                          id="redirectTo"
                          :show-error="
                            $v.form.channel.$error &&
                            !$v.form.channel.required
                          "
                          v-model="form.channel"
                          :items="channelTypes"
                          defaultOption="Elegir tipo de documento"
                        />

                        <form-error
                          message="Tipo de documento es requerido"
                          v-if="
                            $v.form.channel.$error &&
                            !$v.form.channel.required
                          "
                        ></form-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-section border-0 pt-0">
                  <div class="form-group-buttons text-right">
                    <router-link
                      :to="{ name: 'products-list' }"
                      class="button button-light"
                    >
                      {{ editing ? "Volver" : "Cancelar" }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Constants as LocalConstants } from "@/core/utils";
import { Alert, Constants, FormError, FormInput, FormSelect } from "wize-admin";

import { ProductService } from "@/core/services";

import { required, email, numeric } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      identifierTypes: [
        Constants.IdentifierType.DNI,
        Constants.IdentifierType.RUC,
        Constants.IdentifierType.CE,
        Constants.IdentifierType.PASSPORT,
      ],
      channelTypes: [
        {
          key: 1,
          label: 'Canal 1'
        },
        {
          key: 2,
          label: 'Canal 2'
        },
        {
          key: 3,
          label: 'Canal 3'
        }
      ],
      form: {
        identifier: null,
        identifierType: null,
        name: null,
        lastname: null,
        email: null,
        phone: null,
        channel: null
      },
      editing: false,
    };
  },
  components: {
    FormError,
    FormSelect,
    FormInput,
  },
  validations: {
    form: {
      identifierType: { required },
      identifier: { required },
      name: { required },
      lastname: { required },
      email: { required, email },
      phone: { required, numeric },
      channel: { required },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async onChange(files) {
      if (!files.length) return;

      try {
        let filesId = await this.uploadFiles(files);
        filesId = filesId.map((item) => item.id);

        this.product.assetIds.push(...filesId);
      } catch (error) {
        Alert.error(error);
      }
    },
    async save() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.$nextTick(() => {
          this.$smoothScroll({
            scrollTo: document.querySelector(".form-error"),
            updateHistory: false,
            offset: -50,
          });
        });
        return;
      }

      this.$store.dispatch("app/loading", true);
      const data = {};

      try {
        let response;
        if (!this.editing) {
          response = await ProductService.save(data);

          if (response.status == "OK") {
            // Eliminar variaciones anteriores
            this.variations = [];
          }
        } else {
          response = await ProductService.update(this.$route.params.id, data);
        }

        if (response.status == "OK") {
          Alert.success("Se almacenó la información correctamente");
          if (!this.editing) {
            this.$router.push({
              name: "products-edit",
              params: { id: response.payload.id },
            });
            this.editing = true;
          }
        }
      } catch (ex) {
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProductService.get(id);

        let data = {
          ...response.payload,
          assetIds: [],
        };

        this.product = JSON.parse(JSON.stringify(data));
        if (data.priceVariations) {
          const priceVariations = JSON.parse(data.priceVariations);
          if (priceVariations.VOLUME) {
            this.volumePrices = JSON.parse(priceVariations.VOLUME);
          }
        }

        this.product.supplierId = this.product.supplier
          ? this.product.supplier.id
          : null;
        this.editing = true;
      } catch (ex) {
        console.error(ex);
        let message = ex.errors.message;
        if (message == "No encontrado")
          message = Constants.Errors.RECORD_NOT_FOUND;
        Alert.error(message, () =>
          this.$router.push({ name: "products-list" })
        );
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    removeImage(i) {
      this.product.assetIds.splice(i, 1);
    },
  },
  async created() {
    if (this.$route.params.id) await this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus">
@import "../Styles/product-editor.styl"
</style>
