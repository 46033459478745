+
<template>
  <div class="page-container base-batch-list">
    <div class="page-title-container">
      <h1 class="page-title">Detalle del código de pago: {{ $route.params.paymentCode }}</h1>
    </div>

    <div class="table-container">
      <div class="table-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start position-relative"
        >
          <div class="w-full w-md-auto md-flex-1">
            <div
              class="d-flex justify-content-between align-items-center justify-content-lg-start"
            >
              <button
                type="button"
                class="filter d-flex align-items-center justify-content-between border-0"
                @click="showFilters = !showFilters"
              >
                <i class="ri-filter-3-line text-dark font-weight-bold"></i>
                <div>Filtrar</div>
              </button>

              <div class="d-flex d-md-none">
                <button
                  type="button"
                  class="button-tooltip text-dark font-weight-bold"
                  v-b-tooltip.hover
                  title="Recargar"
                  @click="loadData()"
                >
                  <i class="ri-loop-right-line"></i>
                </button>
              </div>
            </div>
          </div>

          <section
            class="w-full w-md-auto md-flex-1 d-flex justify-content-end align-items-center mt-2 mt-md-0"
          >
            <div class="d-none d-md-flex">
              <button
                type="button"
                class="button-tooltip text-dark font-weight-bold"
                v-b-tooltip.hover
                title="Recargar"
                @click="loadData()"
              >
                <i class="ri-loop-right-line"></i>
              </button>
            </div>

            <div class="w-full w-md-auto d-flex flex-column flex-md-row">
              <SearchBar
                placeholder="Buscar por fecha de carga/avance, nombre de archivo"
                @search="onSearch"
                @input="search = $event"
                class="flex-1"
              />
            </div>
          </section>

          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div>

        <div
          class="w-100 d-flex flex-wrap align-items-center mt-2"
          v-if="showFiltersList"
        >
          <span
            v-for="(val, name) in filterList"
            v-bind:key="name"
            class="filter-item"
          >
            {{ getFilterName(name, val) }}
            <button
              type="button"
              class="text-white p-0 bg-transparent border-0 ml-2"
              @click="deleteFilter(name)"
            >
              <i class="ri-close-circle-line"></i>
            </button>
          </span>
        </div>
      </div>

      <div class="mb-0">
        <!-- Mobile -->
        <div class="custom-table-mobile d-xl-none">
          <section class="custom-table-mobile-header">
            <div>
              <span>Segmento</span>
            </div>
            <div>
              <span>Tipo de documento</span>
            </div>
          </section>

          <div class="custom-table-mobile-body">
            <div class="custom-table-mobile-row" v-for="o in list" :key="o.id">
              <div class="custom-table-mobile-grid">
                <div>
                  <span>{{ o.segment }}</span>
                </div>
                <div>
                  <span>{{ getIdentifierType(o.identifierType).label }}</span>
                </div>

                <div>
                  <button
                    type="button"
                    class="border-0 bg-transparent"
                    @click="o.showMobileData = !o.showMobileData"
                  >
                    <span v-if="!o.showMobileData">
                      <i class="ri-arrow-down-s-line"></i>
                    </span>
                    <span v-else>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="pt-3" v-if="o.showMobileData">
                <ul class="list-unstyled p-0">
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Número de documento:</span
                    >
                    <span>{{ o.identifier }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Nombre del impulsador:</span
                    >
                    <span>{{ o.name }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Monto:</span
                    >
                    <span v-decimal:pen="o.amount"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Monto abonado:</span
                    >
                    <span v-decimal:pen="o.amountPaid"></span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Fecha de abono:</span
                    >
                    <span>{{ o.payedAt }}</span>
                  </li>
                  <li>
                    <span class="font-weight-medium d-inline-block mr-2"
                    >Medio de pago:</span
                    >
                    <span>{{ o.paymentMethod }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- Desktop -->
        <table class="custom-table w-100 d-none d-xl-table">
          <thead>
          <tr>
            <th>Segmento</th>
            <th>Tipo de <br> documento</th>
            <th>Número de <br> documento</th>
            <th>Nombre del <br> impulsador</th>
            <th>Monto</th>
            <th>Monto abonado</th>
            <th>Fecha de <br> abono</th>
            <th>Medio de <br> pago</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="o in list" :key="o.id">
            <td class="cell-center px-1">{{ o.segment }}</td>
            <td class="cell-center px-1">{{ getIdentifierType(o.identifierType).label }}</td>
            <td class="text-center">{{ o.identifier }}</td>
            <td class="cell-center">{{ o.name }}</td>
            <td class="cell-center" v-decimal:pen="o.amount"></td>
            <td class="cell-center" v-decimal:pen="o.amountPaid"></td>
            <td class="cell-center">{{ o.payedAt }}</td>
            <td class="cell-center">{{ o.paymentMethod }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
          v-if="list.length"
        />

        <div class="table-no-results" v-else>
          <p class="m-0 px-3">No se encontraron resultados.</p>
        </div>
      </div>
    </div>
    <div class="row buttons-container mb-0 mt-3">
      <div class="col text-right">
        <router-link
          :to="{ name: 'celulares-status' }"
          class="button button-light"
        >
          Volver
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BatchService } from "../../Core/Services";

import { Alert, Constants, FilterMenu, FilterMixin, PaginationMixin, SearchBar, Util } from "wize-admin";

export default {
  components: {
    SearchBar,
    FilterMenu
  },
  data() {
    return {
      list: [
        {
          segment: 'Impulsador',
          identifierType: 1,
          identifier: 12345678,
          name: 'WILMER JOSE RAMIREZ MORALES',
          amount: 70,
          amountPaid: 50,
          payedAt: '21/01/2025',
          paymentMethod: 'Transferencia bancaria'
        }
      ],
      tooltipType: null,
      filterOptions: [
        {
          label: "Fecha de abono",
          key: "payedAt",
          type: Constants.TypeFilters.DATE,
          isRange: true
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    getIdentifierType(key) {
      return Util.searchValue(key, Constants.IdentifierType);
    },
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "createdAt,asc",
        };

        const data = {search: this.search, ...this.filters};
        const response = await BatchService.listItems(this.$route.params.batchId, data, params);

        this.list = response.payload.content.map((item) => {
          return {
            ...item,
            showMobileData: false,
          };
        });

        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async mounted() {
    // await this.loadData();
  },
  async created() {

  },
};
</script>

<style lang="stylus" scoped>
</style>
